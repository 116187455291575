<template>
  <div>
    <p v-if="msgs.length">
      <b>Status:</b>
      <ul>
        <li v-for="msg in msgs" :key="msg">{{ msg }}</li>
      </ul>
    </p>

    <form @submit.stop.prevent="onSubmit" class="form" autocomplete="off">
     <div class="card-body">
      <h3 class="font-size-lg text-dark font-weight-bold mb-6">1. Informações Básicas:</h3>
      <div class="mb-15">
       <div class="form-group row">
        <label class="col-lg-3 col-form-label">Nome Completo: </label>
        <div class="col-lg-6">
         <input v-model="usu_name" required type="text" class="form-control" placeholder="Insira o nome completo"/>
         <span class="form-text text-muted">Por favor insira o nome completo</span>
        </div>
       </div>
       <div class="form-group row">
        <label class="col-lg-3 col-form-label">Endereço Email:</label>
        <div class="col-lg-6">
         <input v-model="usu_email" required type="email" class="form-control" placeholder="Enter email"/>
         <span class="form-text text-muted">Seu email não será compartilhado</span>
        </div>
       </div>
       <div class="form-group row">
        <label class="col-lg-3 col-form-label">Senha: (Em branco para manter a atual)</label>
        <div class="col-lg-6">
          <input 
            v-model="usu_password" 
            autocomplete="new-password" 
            
            type="password" 
            class="form-control" 
            placeholder="Insira a senha"/>
        </div>
       </div>
       <div class="form-group row">
         <label class="col-lg-3 col-form-label">Imagem:</label>
         <img class="px-4 py-2" :src="image_file_path" width="100px">  
         <div class="col-lg-6">
           <input
            type="file"
            name="cat_image"
            accept="image/*"
            id="img_file" 
            ref="img_file"
            @change="handleImageUpload($event)"
            class="input-file">
          <span class="form-text text-muted">Selecione uma imagem para o usuário</span>
         </div>
        </div>
      </div>

      <h3 class="font-size-lg text-dark font-weight-bold mb-6">2. Dados da Conta:</h3>
      <div class="mb-3">

        <div class="form-group row">
          <label class="col-lg-3 col-form-label">Tipo Usuário</label>
          <div class="col-lg-6">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
              <b-form-select v-model="usu_role_id" require :options="user_types_list" />
            </div>
          </div>
        </div>
        
         <div class="form-group row">
          <label class="col-lg-3 col-form-label">Contato</label>
          <div class="col-lg-6">
           <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
            <input v-model="usu_cellphone" required type="text" class="form-control" placeholder="Número Telefone"/>
           </div>
          </div>
         </div>
       </div>
     </div>

     <div class="card-footer">
      <div class="row">
       <div class="col-lg-3"></div>
       <div class="col-lg-6">
         <b-button v-if="$route.name == router_actions.route_new" type="submit" class="btn btn-success mr-2">Cadastrar</b-button>
         <b-button v-if="$route.name == router_actions.route_edit" type="submit" class="btn btn-success mr-2">Atualizar</b-button>
         <b-button type="reset" class="btn btn-secondary">Cancelar</b-button>
       </div>
      </div>
     </div>

    </form>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "user",
  props: {
    id: String,
    router_actions: Object
  },
  mounted() {

    if(this.$route.name == this.router_actions.route_edit){
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/" },
        { title: "Listagem Usuários", route: '/Users/list' },
        { title: "Editar Usuário", route: '/Users/edit'+this.$route.params.id }
      ]);
      let id = this.$route.params.id;
      ApiService.get('users/'+id)
        .then(({data}) =>{
          this.msgs.push(data.message);
          this.usu_name = data.usu_name;
          this.usu_email = data.usu_email;
          // this.usu_password = data.usu_password;
          this.usu_cellphone = data.usu_cellphone;
          this.usu_role_id = data.usu_role_id;
          
          let usu_image = data.usu_image
          this.image_file_path = this.base_url + 'images/' + id + '/' + usu_image
          ApiService.get(this.image_file_path)
            .then(({data}) =>{
              var file = new File([data], usu_image);
              this.usu_image = file;
            })
            .catch((error) => {
              this.errors.push(error.message);
            });

        })
          .catch(error => {
            this.msgs.push(error.message);
          });
    }else{
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/" },
        { title: "Listagem Usuários", route: '/Users/list' },
        { title: "Cadastrar Usuário", route: '/Users/new' }
      ]);
      this.usu_name = '';
      this.usu_email = '';
      this.usu_password = '';
      this.usu_cellphone = '';
      this.usu_role_id = 0;
      this.usu_image = '';
    }

  },
  components: {},
  data() {
    return {
      usu_name : '',
      usu_email : '',
      usu_password : '',
      usu_cellphone : '',
      user_types_list : [
        {
          value: 2,
          text: "Super User"
        },
        {
          value: 1,
          text: "Administrador"
        },
        {
          value: 2,
          text: "Usuário Comum"
        }
      ],
      usu_image : {},
      msgs : [],
      usu_role_id : 0,
      image_file_path: '',
      base_url : 'http://api.prcweb.com.br/' //'http://localhost:4000/'
    }
  },
  methods: {
    handleImageUpload(event){
      this.image_file_path = URL.createObjectURL(event.target.files[0])      
      this.usu_image = event.target.files[0];

      // let usu_image = new FormData();
      // usu_image.append('file', event.target.files[0]); 
      // ApiService.anonUploadImg(usu_image)
      //   .then(({data}) => {
      //     this.image_file_path = ApiService.baseURL + "Etc/anonGetImg/" + data.file.name;
      //   })
      //     .catch(({response}) => {
      //       this.errors,push(response.data.errors);
      //     });
    },
    onSubmit(e) {

      let id = null, rota = null, metodo = null;
      if(this.msgs.length){
        this.msgs = [];
      }

      let user = {
        usu_name: this.usu_name,
        usu_email: this.usu_email,
        usu_cellphone: this.usu_cellphone,
        usu_image: this.usu_image,
        usu_role_id: this.usu_role_id,
      };

      if(this.usu_password.length){
        user.usu_password = this.usu_password;
      }

      if(this.$route.name == this.router_actions.route_edit){
        id = this.$route.params.id;
        metodo = 'put';
        rota = id;
      }else{
        metodo = 'post';
        rota = '';
      }

      ApiService[metodo]('users/'+rota, user)
        .then(({data}) => {
          if(data.status){
            this.msgs.push('Usuário adicionado com sucesso');  
            this.$router.push({ name: "users-list" })
          }
        })
          .catch(error => {
            if(error.response && error.response.data.errors){
              this.msgs.push(error.response.data.errors);
            }else{
              this.msgs.push(error.message);
            }
          });

      e.preventDefault();
    }
  },
};
</script>
